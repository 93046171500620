@import "sass-material-colors";

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  background-color: white;
  background-size: auto 85%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 80vw;
  max-width: 500px;
  height: calc(80vh - 56px);
  max-height: 700px;
  will-change: transform;
  border-radius: 10px;
  box-shadow: 0 12.5px 50px -10px rgba(120, 144, 156, 0.3),
    0 10px 10px -10px rgba(120, 144, 156, 0.6),
    0 10px 80px -10px rgba(120, 144, 156, 0.1);

  .header {
    flex: 0 0 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 2px solid material-color("grey", "900");

    font-size: 40px;

    img {
      height: 150px;
    }

    @media (max-width: 450px) {
      flex: 0 0 35%;
      img {
        height: 120px;
      }
    }
  }
  .content {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    color: material-color("grey", "900");
    font-size: 40px;

    @media (max-width: 600px) {
      font-size: 24px;
    }

    &.topic-content {
      padding: 20px;
    }
  }
  .background-image {
    position: absolute;
    width: 50%;
  }

  .brand {
    flex: 0 0 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: material-color("grey", "400");
    flex: 0 0 auto;
    font-size: 20px;
    text-transform: lowercase;

    img {
      height: 50px;
    }
  }

  .download-button {
    margin-top: 20px;

    &.inverted {
      color: white;
      border: 1px solid white;
    }
  }

  .card-brand {
    img {
      height: 120px;

    }

    @media (max-width: 600px) {
      img {
        height: auto;
        max-width: 60vw;
      }
    }
  }

  .card-instruction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: material-color("grey", "900");
    color: white;
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .card-instruction-header {
      margin-bottom: 50px;

      img {
        height: 150px;
      }

      @media (max-width: 600px) {
        margin-bottom: 30px;
        font-size: 40px;
        img {
          height: 120px;
        }
      }
    }

    .card-instruction-content {
      margin-bottom: 20px;
      padding: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      @media (max-width: 600px) {
        font-size: 24px;
        padding: 0;
      }
    }
  }




  .deck-header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    color: material-color("grey", "900");
    padding: 50px 20px 20px 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;

    .text {
      margin-bottom: 50px;

      img {
        height: 150px;
      }

      @media (max-width: 600px) {
        margin-bottom: 30px;
        font-size: 40px;
        img {
          height: 120px;
        }
      }
    }

    .image {
      position: absolute;
      bottom: 10px;
      height: 280px;

      @media (max-width: 600px) {
        left: -58px;
        height: auto;
        width: 400px;
      }
    }
  }
}


