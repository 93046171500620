@import "sass-material-colors";

.about-us {
  // background-color: material-color("grey", "200");
  padding: 50px 100px;

  @media (max-width: 600px) {
    padding: 30px 20px;
  }

  .section-header {
    text-align: center;
    margin-bottom: 50px;

    @media (max-width: 600px) {
      margin-bottom: 20px;
    }
  }
}
