@import "sass-material-colors";

.landing-hero {
  margin-top: 50px;
  margin-bottom: 50px;
  .left,.right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .left {
    .logo {
      height: 180px;

      @media (max-width: 600px) {
        height: 80px
      }
    }
    .sub-title {
      margin-top: 100px;
      color: material-color("grey", "500");
      text-align: center;
      padding: 50px 100px;

      .with-line-spacing {
        margin-top: 50px;
      }

      @media (max-width: 600px) {
        margin-top: 30px;
        padding: 30px 20px;
        font-size: 24px;
      }
    }
  }

  .right {
    .logo {
      height: 500px;

      @media (max-width: 600px) {
        height: auto;
        max-height: 400px;
        max-width: 80vw;
      }
    }

    .buttons {
      display: flex;

      .button {
        margin-top: 20px;
        background-color: material-color("grey", "900");
        margin-right: 20px;
      }
    }



    @media (max-width: 600px) {
      margin-top: 20px;
    }
  }
}
