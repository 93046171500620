@import "sass-material-colors";

.menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

.title {

  img {
    height: 50px;
  }
  .name {
    color: material-color("grey", "900");
    font-size: 40px;
    margin: 10px;
  }
  .link {
    color: material-color("grey", "500");
    margin: 0 10px 10px;
  }
}
