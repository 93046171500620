.banner {
  background-color: white !important;
  .left {
    flex: 1 1 auto;
  }

  .logo {
    height: 50px;

    @media (max-width: 600px) {
      height: 40px;
    }
  }
}
