.all-cards {
  background-color: white;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;

  a {
    text-decoration: none !important;
  }

  .grid {
    width: 100%;
    margin-top: 20px;
  }

  .all-card {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 100%;
      margin-bottom: 10px;
    }

    .all-button {

      .MuiButton-label {
        text-decoration: none;
      }
    }
  }
}
