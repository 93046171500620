@import "sass-material-colors";

.instructions {
  width: 100%;
  background-color: material-color("grey", "200");

  .text {
    padding: 50px 100px;
    text-align: center;

    @media( max-width: 600px) {
      padding: 30px 20px
    }

    .section-header {
      text-align: center;
      margin-bottom: 50px;

      @media (max-width: 600px) {
        margin-bottom: 20px;
      }
    }
  }

  .list-icon {
    height: 40px;
  }
}



