@import "sass-material-colors";

* {
  box-sizing: border-box;
}

html,
body {
  overscroll-behavior-y: contain;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  user-select: none;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  position: fixed;
  overflow: hidden;
}

#root {
  background: material-color("grey", "200");
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  // cursor: url("https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png")
  //     39 39,
  //   auto;
}

.deck,
.card-container {
  position: absolute;
  width: 100%;
  height: calc(100vh - 100px);
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
}

.complete {
  text-align: center;
  h1 {
    margin-bottom: 100px;
  }
}

.deck-container {
  position: relative;
  width: 100%;
  height: calc(100% - 56px);

  .deck-next-button {
    z-index: 20;
    position: absolute;
    right: 100px;
    bottom: calc(50% + 28px);
    @media (max-width: 1000px) {
      right: 10px;
      bottom: 10px;
    }
  }

  .deck-prev-button {
    z-index: 20;
    position: absolute;
    left: 100px;
    bottom: calc(50% + 28px);
    @media (max-width: 1000px) {
      left: 10px;
      bottom: 10px;
    }
  }


}


