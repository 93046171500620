.landing {
  background-color: white;
  height: 100vh;
  width: 100vw;
  overflow-x: auto;

  .landing-grid {
    width: 100%;
    overflow-y: auto;
  }

  .section {
    margin-bottom: 30px;
    min-height: 300px;
  }
}


